svg:not(:root) {
  overflow: unset;
}
html {
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
}

div {
  font-family: 'Open Sans', sans-serif;
  font-family: 'Poppins', sans-serif;
}
body {
  margin: 0;
  font-weight: $font-regular;
  background: #f8f8f8;
  font-family: 'Open Sans', sans-serif;
  font-family: 'Poppins', sans-serif;
}
h1,
.h1,
h2,
.h2
// h3,
// .h3,
// h4,
// .h4,
// h5,
// .h5,
// h6,
// .h6
{
  margin-top: 0;
  margin-bottom: 0.1rem;
  color: color(text-dark);
  line-height: 150%;
  font-family: 'Open Sans', sans-serif;
  font-family: 'Poppins', sans-serif;
}
h1 {
  font-size: $h1 !important;
  font-weight: $font-bold !important;
}
h2 {
  font-size: $h2 !important;
  font-weight: $font-semi-bold !important;
  color: color(text-dark);
}
h3 {
  font-size: $h3 !important;
  font-weight: $font-semi-bold !important;
  color: color(text-dark);
}
// h3 {
//   font-size: $h3;
//   font-weight: $font-bold;
// }
// h4 {
//   font-size: $h4;
// }

p {
  margin-top: 0;
  line-height: 170%;
  font-size: $font-regular;
}
button,
textarea,
input {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
}

a {
  text-decoration: none !important;
  color: unset;
  &:hover {
    color: color(secondary, base);
  }
}

ul {
  list-style: none;
  padding: 0;
}

.clearfix {
  &:before,
  &:after {
    display: table;
    content: '';
  }
  &:after {
    clear: both;
  }
}

// cursor pointer

.form-check-input {
  cursor: pointer;
}

//table customization
thead {
  background: color(success, base);
  color: white;
}
th {
  font-weight: $font-medium;
  font-size: $text-lg;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
}
td {
  border: none !important;
  padding: 0.7rem !important;
  font-size: $text-sm;
  color: color(text-dark);
}

thead {
  border-style: hidden !important;
}
