.reviews {
    display: flex;
    flex-direction: column;
    // gap: rem;
    padding-bottom: 4rem;

    &-item {
        @include container;

        .rcard {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            &-product {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 1rem;

                &_img {
                    height: 3.75rem;
                    width: 3.75rem;
                    border: 1px solid color(gray, four);
                    border-radius: 0.25rem;
                    &--item {
                        border-radius: 0.25rem;
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                    }
                }

                &_main {
                    display: flex;
                    flex-direction: column;
                    gap: 0.75rem;
                }

                &_det {
                    display: flex;
                    flex-direction: row;
                    gap: 1.5rem;
                    align-items: center;
                }

                &_title {
                    font-size: 1.125rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.375rem;
                    color: color(text-dark);
                }

                &_row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 0.75rem;
                }

                &_user {
                    color: color(text-light);
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 500;
                }

                &_dot {
                    color: color(text-extra-light);
                }

                &_date {
                    color: color(text-extra-light);
                    font-size: 0.875rem;
                    font-style: normal;
                }
            }

            &-main {
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }

            &-text {
                font-size: 0.875rem;
                font-style: normal;
                line-height: 1.375rem;
                margin: 0;
                padding: 0;
            }

            &-imgs {
                display: flex;
                flex-direction: row;
                column-gap: 0.75rem;
                row-gap: 0.5rem;
                flex-wrap: wrap;

                &_item {
                    height: 5.65rem;
                    width: 7.5rem;
                    border: 1px solid color(gray, four);
                    border-radius: 0.25rem;

                    img {
                        height: 100%;
                        width: 100%;
                        border-radius: 0.25rem;
                        object-fit: contain;
                    }
                }
            }

            &-btns {
                display: flex;
                flex-direction: row;
                gap: 0.75rem;

                &_item {
                    margin-top: 1rem;
                    padding: 0.375rem 0.75rem;
                    width: 6rem;
                    background-color: transparent;
                    border: none;
                    outline: none;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.25rem;
                    color: color(text-light);
                    transition: all 150ms;

                    &:hover {
                        background-color: color(gray, four);
                    }
                }
            }
        }
    }

    &-empty {
        display: flex;
        height: 50vh;
        width: 100%;
        align-items: center;
        justify-content: center;

        &_main {
            background-color: #fdf2f4;
            color: #333;
            padding: 1.5rem;
            max-width: 20rem;

            p {
                margin: 0;
            }
        }
    }

    &-pag {
        background: color(white);
        padding-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
        border-radius: 8px;
        box-shadow: $shadow;
    }

    &-reject {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        padding-top: 1.5rem;
        padding-right: 1.5rem;
        width: 100%;

        &_input {
            width: 100%;
            height: 3rem;
            resize: none;
            border: none;
            outline: none;
            border-bottom: 2px solid color(gray, two);
            font-size: 0.875rem;
            color: #333;
        }

        &_error {
            font-size: 0.75rem;
            color: color(danger, base);
        }

        &_btns {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            gap: 1.5rem;

            &--cancel {
                font-size: 0.875rem;
                border-radius: 0.25rem;
                background-color: transparent;
                outline: none;
                width: 6rem;
                border-radius: 3rem;
                border: none;
                padding: 0.25rem 0.75rem;

                &:hover {
                    background-color: color(danger, tint);
                }
            }

            &--submit {
                font-size: 0.875rem;
                border-radius: 0.25rem;
                background-color: color(gray, two);
                outline: none;
                width: 6rem;
                border-radius: 3rem;
                border: none;
                padding: 0.25rem 0.75rem;

                &:hover {
                    background-color: color(success, tint);
                }
            }
        }
    }
}

.star-rating {
    display: flex;
    flex-direction: row;
    gap: 0.3rem;
    align-items: center;

    &_icon {
        color: #f6b400;
    }
}

.rmodal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    overflow-y: auto;

    &-bg {
        z-index: 1;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
    }

    &-main {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem 0;
        width: 100%;
        min-height: 100%;
    }

    &-dialog {
        z-index: 50;
        border-radius: 0.5rem;
        background-color: color(white);
        border: none;
        width: max-content;
        min-width: 22rem;
        max-width: 28rem;

        &_header {
            display: flex;
            flex-direction: row;
            justify-content: center;
            text-align: center;
            align-items: center;
            border-bottom: 1px solid color(gray, two);
            padding: 1rem 1.3rem;

            &--text {
                font-weight: 600;
                font-size: 1.25rem;
                color: color(text-dark);
            }
        }
    }

    &-main {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 2rem;

        &_msg {
            color: var(--navi, #333);
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5rem;
            text-align: center;
        }

        &-btns {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
            justify-content: center;

            &--apr {
                font-size: 0.875rem;
                border-radius: 0.25rem;
                background-color: color(success, tint);
                color: color(success, base);
                outline: none;
                width: 6rem;
                border-radius: 3rem;
                border: none;
                padding: 0.5rem 0.75rem;
                transition: all 150ms;

                &:hover {
                    background-color: color(success, base);
                    color: white;
                }
            }

            &--cancel {
                font-size: 0.875rem;
                border-radius: 0.25rem;
                background-color: #808080;
                color: white;
                outline: none;
                width: 6rem;
                border-radius: 3rem;
                border: none;
                padding: 0.5rem 0.75rem;
                transition: all 150ms;

                &:hover {
                    background-color: #919191;
                }
            }
        }
    }

    &_spin {
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}
