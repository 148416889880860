.ManCatWrapper {
  @include container;
  .MainCatCard {
    border: $border;
    margin-top: 2rem;
    padding: 0.5rem;
    &-image {
      // text-align: center;
      img {
        width: 100%;
        height: 7rem;
        object-fit: cover;
      }

      figcaption {
        margin-top: 0.5rem;
      }
    }

    &-text {
      height: 2rem;
      font-size: 14px;
    }
  }
}

.CatWrapper {
  @include container;

  .categories {
    &-tabs {
      margin-top: 2rem;
      margin-bottom: 1rem;
      .active {
        cursor: pointer;

        padding: 1rem;
        background: color(secondary, base);
        border-radius: $radius;
        color: white;
        width: 50%;
        font-size: 13px;
      }
      .passive {
        cursor: pointer;
        border-radius: $radius;
        background: rgb(243, 243, 243);
        padding: 1rem;
        width: 50%;
        font-size: 13px;
      }
    }
  }
}
.galleryWrapper {
  @include container;
  .center {
    text-align: center;
    border: 1px dashed color(primary, base);
    padding: 30px;
    margin: 15px 0;

    span {
      margin-top: 3px;
    }
  }

  .preview {
    height: 22rem;
    width: 100%;
    border: $border;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .imageList {
    margin-top: 1rem;
    height: 11rem;
    width: 11rem;
    background: rgb(184, 184, 184);
    border: $border;
    position: relative;
    cursor: pointer;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .editIcon {
      position: absolute;
      text-align: center;
      top: 7px;
      right: 10px;
      background: white;
      border: $border;
      padding: 0.2rem;
      height: 2rem;
      width: 2rem;
      border-radius: calc(2rem);

      cursor: pointer;
      color: rgb(49, 49, 49);
    }
    .deleteIcon {
      position: absolute;
      text-align: center;

      top: 7px;
      right: 5px;
      background: white;
      border: $border;
      padding: 0.2rem;
      height: 2rem;
      width: 2rem;
      border-radius: calc(2rem);

      cursor: pointer;
      color: rgb(49, 49, 49);

      i {
        color: color(danger, base);
      }
    }
  }
}

.preview {
  img {
    width: 100%;
  }
}

.descProduct {
  @include container;

  .Desclist {
    ul {
      padding: 0;
    }
    &-items {
      padding: 0.5rem;
      i {
        cursor: pointer;
      }
    }
  }

  .tagsHolder {
    border: $border;
    margin-top: 0.5rem;
    padding: 0.5rem;

    .tagPills {
      display: inline-flex;
      padding: 0.5rem;
      border: $border;

      border-radius: 6px;
    }
  }
}

.ProductsWrapper {
  @include container;
  .products-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .productSearch {
    display: flex;
    flex-direction: row;
    // align-items: center;
    gap: 1rem;

    &-input {
      width: 300px;
      height: 38px;
    }

    &-bton {
      border-radius: 4px;
      // height: 38px;
      padding: 0 1.5rem;

      border: 1px solid color(primary, base);
      color: color(primary, base);
      background-color: transparent;
      &:hover {
        background-color: color(primary, hover);
        border-color: color(primary, hover);
        color: white;
      }
      &:active {
        border-color: color(primary, clicked);
      }
    }
  }

  .productFilter {
    display: flex;
    justify-content: flex-end;
    gap: 1.5rem;
  }

  .tableHolder {
    .productList {
      display: flex;
      &-image {
        background: rgb(241, 241, 241);
        border: $border;
        margin: 0;
        img {
          height: 6rem;
          width: 6rem;
          object-fit: cover;
        }
      }
      &-name {
        margin-left: 0.5rem;
      }
    }
  }
}

//slider Carousel here

.sliderHolder {
  border: $border;
  background: white;
  padding: 1rem;
  height: 100%;

  margin-top: 1rem;

  &-image {
    height: 12rem;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .linkCarousel {
    margin-top: 1rem;
    text-decoration: underline;
    height: 3rem;
    overflow: hidden;
    margin-bottom: 1rem;
  }
}

.relatedDropdown {
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 10px;
  font-size: 15px;
  cursor: pointer;

  &:hover {
    transform: scale(1.01);
  }
}

.variants {
  display: flex;
  flex-direction: column;

  &_sub {
    font-size: 0.8rem;
    color: #626262;
  }

  &-popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    width: 100%;

    &_overlay {
      background-color: rgba(0, 0, 0, 0.2);
      position: fixed;
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 30;
    }

    &_container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100%;
      width: 100%;
      z-index: 200;
    }
  }

  &-update {
    z-index: 50;
    display: flex;
    gap: 0.8rem;
    flex-direction: column;
    background-color: #fff;
    padding: 1rem 1.3rem 1.3rem 1.3rem;
    width: 280px;
    border-radius: 8px;

    &_header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      button {
        background-color: transparent;
        border: none;
        outline: none;
      }
    }

    &_main {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }

    &_label {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 0.2rem;
    }

    &_delete {
      display: flex;
      flex-direction: column;

      .delete_label {
        color: color(danger, base);
        font-weight: 500;
      }
      .delete_sub {
        font-size: 0.75rem;
        margin-bottom: 0.3rem;
        span {
          font-weight: 500;
        }
      }
    }
  }

  &-delete {
    z-index: 50;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    background-color: #fff;
    padding: 1rem 1.3rem 1.5rem 1.3rem;
    border-radius: 8px;
    width: 420px;

    &_header {
      display: flex;
      justify-content: flex-end;

      button {
        background-color: transparent;
        border: none;
        outline: none;
      }
    }

    &_main {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
    }

    &_label {
      text-align: center;
      font-weight: 500;
    }

    &_btns {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      .confirm-btn {
        margin: 0;
        width: 100px;
        background: transparent;
        border: 1px solid color(danger, base);
        color: color(danger, base);

        &:hover {
          background-color: color(danger, tint);
        }
      }

      .cancel-btn {
        margin: 0;
        width: 100px;
        background: color(secondary, base);
        border: 1px solid color(secondary, base);
        color: color(white);

        &:hover {
          background-color: color(secondary, hover);
          border-color: color(secondary, hover);
        }
      }
    }
  }

  &-upload {
    z-index: 50;
    display: flex;
    gap: 0.8rem;
    flex-direction: column;
    background-color: #fff;
    padding: 1rem 1.3rem 1.3rem 1.3rem;
    width: 675px;
    border-radius: 8px;

    &_header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      button {
        background-color: transparent;
        border: none;
        outline: none;
      }
    }

    &_main {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 1.5rem;
    }

    &_btn {
      width: 140px;
      height: 140px;
      border-radius: 8px;
      border: 1px dashed color(gray, two);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        color: #1058c5;
        font-size: 0.8rem;
      }
    }

    &_img {
      width: 140px;
      height: 140px;
      border: 1px solid color(gray, two);
      border-radius: 8px;
      outline: none;
      background-color: transparent;

      img {
        border-radius: 8px;
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
    .selected {
      border: 2px solid black;
    }

    &_btns {
      align-self: flex-end;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      button {
        font-size: 14px;
      }

      .bton--nacked {
        color: #000;
      }
    }
  }
}

.variant {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  min-height: 200px;
  border-radius: 8px;
  border: 1px solid #ddd;
  padding: 1rem;

  &-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    span {
      font-size: 1.1rem;
      font-weight: 500;
    }
    .edit-btn {
      border: none;
      outline: none;
      font-size: 15px;
      color: #1058c5;
      background-color: transparent;
    }
  }

  &-subtype {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_input {
      padding-right: 50px;
    }

    &_btn {
      position: absolute;
      right: 10px;
      background-color: transparent;
      border: none;
      outline: none;
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;

    &_item {
      border-radius: var(--bs-border-radius);
      border: var(--bs-border-width) solid var(--bs-border-color);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.375rem 0.75rem;

      &-icons {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.8rem;
        .edit {
          background-color: transparent;
          border: none;
          outline: none;
          color: color(text-light);
          padding: 0;
        }
        .dlt {
          background-color: transparent;
          border: none;
          outline: none;
          color: color(danger, base);
          padding: 0;
        }
      }
    }
  }
}

.variant-products {
  &_table {
    // width: 100%;
    // margin: 0;
    margin-top: 1rem;
    padding: 0.5rem !important;
    &_head {
      background-color: transparent !important;
      color: #000;
    }

    &_body {
    }

    &_row {
      border-bottom: 1px solid color(gray, two);
    }

    &_th {
      font-size: 0.9rem;
      font-weight: 500;
      padding: 0 0.5rem !important;
    }

    &_td {
      min-width: 100px;
      padding: 1rem 0.5rem !important;
    }

    &_image {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px dashed color(gray, two);
      cursor: pointer;
      background-color: transparent;
      outline: none;
      padding: 0;

      img {
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
      }

      i {
        color: color(text-light);
      }
    }

    &_name {
    }

    &_input {
      input {
        font-size: 14px !important;
        width: 100px;
      }
    }
  }
}
