.GDNBox{

    @include container;
    cursor: pointer;

    &-title{
        font-size: $text-lg;
        font-weight: $font-semi-bold;
    }
    &-address{
        font-size: $text-sm;
        font-weight: $font-regular;
        color: color(text-light);
    }
    &-date{
        margin-top: 1rem;
        font-size: $text-md;
        font-weight: $font-regular;
        color: color(text-light);
    }
}

.POHistoryCard{
    
    @include container;
    cursor: pointer;

    &-title{
        font-size: $text-lg;
        font-weight: $font-semi-bold;
    }
    &-address{
        font-size: $text-sm;
        font-weight: $font-regular;
        color: color(text-light);
    }
    &-span{
        margin-top: .8rem;
        font-size: $text-sm;
        font-weight: $font-regular;
        color: color(text-light);
    }
}

/// GDN page CSS ///@at-root

.GDNWrapper{
    @include container;
    .GDNTableWrapper{
        margin-top: 1.5rem;
    }
}