@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin position-center {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
}
@mixin container {
  background: color(white);
  padding: 0.8rem;
  margin-top: 1rem;
  border-radius: 8px;
  box-shadow: $shadow;
}

@mixin truncate {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  /* autoprefixer: off */
}
@mixin loader-element($width, $height, $border-radius) {
  background-color: #dddddd;
  width: $width;
  height: $height;
  border-radius: $border-radius;
}
@mixin loader-animation {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: preloadAnim;
  animation-timing-function: linear;
  background: linear-gradient(
    to right,
    transparent,
    #dddddd 10%,
    transparent 33%
  );
  width: 100%;
  height: 100%;
}
@keyframes preloadAnim {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
// BREAK POINTS FOR THE DEVICES
$xs: 480px;
$sm: 576px; // FOR SMALL DEVICES == sm
$md: 768px; // FOR MEDIUM DEVICES == md
$lg: 992px; // FOR LARGE DEVICES == lg
$xlg: 1200px; // FOR EXTRA LARGE DEVICES == xlg

@mixin responsive-for($size) {
  @if $size == xs {
    @media all and (max-width: $xs) {
      @content;
    }
  } @else if $size == sm {
    @media all and (max-width: $sm) {
      @content;
    }
  } @else if $size == md {
    @media all and (max-width: $md) {
      @content;
    }
  } @else if $size == lg {
    @media all and (max-width: $lg) {
      @content;
    }
  } @else if $size == xlg {
    @media all and (max-width: $xlg) {
      @content;
    }
  }
}
