section[role="notification"]{

    .dropdown-toggle::after {
        display: none !important;
    }

    .btn{
        padding: 0;
        background: none;
        border: none;
        
    }

    .show>.btn-success.dropdown-toggle {
      
        background-color: white !important;
      
    }

    .dropdown-menu.show {
        width: 30rem;
        max-height: 70vh;
        box-shadow: $shadow;
        overflow-y: auto;
        
    }

}