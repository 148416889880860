/* 2.3 Font weight*/
$font-thin: 100;
$font-extra-light: 200;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;
$font-extra-bold: 800;

/* 2.1 Font Size*/
$text-xlg: 24px;
$text-lg: 18px !default;
$text-md: 16px !default;
$text-sm: 14px !default;
$text-xs: 12px;

/* 3.0 Heading Size*/
$h1: 24px !default;
$h2: 20px !default;
$h3: 18px !default;
// $h4: 20px;
// $h4: $large;
// $h5: $medium;
// $h6: $base;

$radius-buttons: 4px;
$radius: 6px;
$shadow: 1px 1px 14px 6px #bdbdbd10;
// $shadow-hover: 2px 2px 10px #93d3fd;

$border: 1px solid rgb(230, 230, 230);

// color variable map
$colors: (
  // non-nested values
  white: #ffffff,
  text-dark: #1a1a1a,
  text-light: #4d4d4d,
  text-extra-light: #959595,
  // nested map inception
  primary:
    (
      base: #0071bd,
      hover: #b3e1ff,
      tint: #ffe1e0,
      tint2: #3e6ec8,
      btn: #315cac,
    ),
  secondary: (
    base: #34c191,
    hover: #148a62,
    tint: #d0ffef,
  ),
  success: (
    base: #3cc13b,
    tint: #d7f3d7,
  ),
  danger: (
    base: #f03738,
    tint: #fcd7d7,
  ),
  warning: (
    base: #f3bb1c,
    tint: #fdf1d1,
  ),
  gray: (
    one: #b3b3b3,
    two: #cccccc,
    three: #e6e6e6,
    four: #f4f4f4,
  )
);

// retrieve color from $colors map ie. `color(base, primary)`
@function color($color-name, $color-variant: null) {
  // color variant is optional
  @if ($color-variant != null) {
    // map inception
    @return map-get(map-get($colors, $color-name), $color-variant);
  } @else {
    @return map-get($colors, $color-name);
  }
}
