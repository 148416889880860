.navList {
  margin: 0;
  padding: 0 0.5rem;
  &-items {
    margin-bottom: 0.2rem;
    a {
      padding: 0.7rem 1rem;
      width: 100%;
      display: block;
      color: color(text-dark);
      font-weight: $font-medium;

      &:hover {
        background: color(primary, tint);
        color: color(text-light);
      }
    }
  }
}

.activeSidebar {
  background: color(primary, base);
  color: white !important;

  &:hover {
    background: color(primary, base) !important;
    color: white !important;
  }
}

.accordion-item {
  border: none !important;
}

.accordion-button:not(.collapsed) {
  color: #15284a !important;
  box-shadow: none !important;
}

//accordion
.accordion-button:not(.collapsed) {
  background-color: color(primary, tint) !important;
}

.accordion-button {
  &:hover {
    background: color(primary, tint) !important;
  }
}

// .accordion-button:not(.collapsed)::after {
//   background-image: url('../assets/images/brand_image.png') !important;
// }

.accordion-button:not(.collapsed)::after {
  background-image: url('../assets/images/up.png') !important;
}
